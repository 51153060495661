import { useEffect } from "react";
import NavBar1 from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";

function Basic({ message, setMessage, page, children }) {
  return (
    <div className="App  d-flex flex-column">
      <NavBar1 />
      <main className="flex-grow-1 px-3 d-flex flex-column" style={{ paddingTop: "75px" }}>
        <div className="shadow-lg mb-3 fs-5 text-center fw-bolder fst-italic">
          {page}
        </div>
        <div className="flex-grow-1 d-flex flex-column">
          {children}
        </div>

      </main>
      <Footer />
    </div>
  );
}

export default Basic;

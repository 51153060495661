function Footer() {
  return (
    <footer className="text-center back-color1 py-2 fw-bolder fst-italic text-white
    ">
      © 2023 mati optique
    </footer>
  );
}

export default Footer;

import { useState } from "react";
import Basic from "../../layouts/basic/Basic";
import { useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import Image from "react-bootstrap/Image";
import { Column } from "primereact/column";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { FcKindle, FcEmptyTrash } from "react-icons/fc";
import { ToastContainer, toast } from "react-toastify";
import { db } from "../../firebase";
import { setDoc, doc } from "firebase/firestore";

function Orders() {
  const [modalShow, setModalShow] = useState(false);
  const [cart, setCart] = useState([]);
  const Orders = useSelector(state => state.ordersReduce);

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const notify = async message => {
    await sleep(500);
    toast.success(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored"
    });
  };

  const fullNameTemplate = rowData => {
    return <>{rowData.firstName + " " + rowData.lastName}</>;
  };

  const dateBodyTemplate = rowData => {
    if (!!rowData.createdAt) {
      const date = new Date(rowData.createdAt.seconds * 1000);
      return <span>{date.toLocaleString().toString()}</span>;
    }
  };

  const totalBodyTemplate = rowData => {
    return (
      <span>
        {rowData.cart.reduce((acc, item) => acc + item.qty * item.price, 0) +
          rowData.delevery_price}{" "}
        DA
      </span>
    );
  };
  const actionTemplate = rowData => {
    return (
      <div className="d-flex text-start">
        <div
          style={{
            height: "40px",
            width: "40px",
            backgroundColor: "#fcd7e7"
          }}
          className="rounded-circle me-2 d-flex justify-content-center align-items-center"
        >
          <FcKindle
            size={30}
            onClick={() => {
              setCart(rowData.cart);
              setModalShow(true);
            }}
          />
        </div>
        <div
          style={{
            height: "40px",
            width: "40px"
          }}
          className="bg-danger rounded-circle d-flex justify-content-center align-items-center"
        >
          <FcEmptyTrash size={30} onClick={() => handleDelete(rowData)} />
        </div>
      </div>
    );
  };

  const statusBodyTemplate = rowData => {
    let color = "#fff";
    if (rowData.status === "new") {
      color = "#fff";
    }
    if (rowData.status === "preparing") {
      color = "#e1a626";
    }
    if (rowData.status === "ready") {
      color = "#25c7bf";
    }
    if (rowData.status === "delivery_in_progress") {
      color = "#8c5fa5";
    }
    if (rowData.status === "complete") {
      color = "#49a688";
    }
    if (rowData.status === "canceled") {
      color = "#e87778";
    }
    return (
      <Form.Select
        aria-label="حالة الطلب"
        className={rowData.status === "new" ? `text-dark` : `text-white`}
        value={rowData.status}
        onChange={e => {
          setDoc(doc(db, "orders", rowData.id), {
            ...rowData,
            status: e.target.value
          });
          notify("status update Done");
        }}
        style={{
          backgroundColor: `${color}`,
          zIndex: "9000"
        }}
      >
        {rowData.deleveryStatus !== "5" && (
          <>
            <option value="new">new</option>
            <option value="preparing">preparing</option>
            <option value="ready">ready</option>
            <option value="delivery_in_progress">Delivery in progress</option>
          </>
        )}
        <option value="complete">complete</option>
        {rowData.deleveryStatus !== "canceled" && (
          <option value="canceled">canceled</option>
        )}
      </Form.Select>
    );
  };

  const handleDelete = async data => {
    setDoc(doc(db, "orders", data.id), { ...data, deleted: true }).then(
      notify("Delete Done")
    );
  };
  return (
    <Basic page="Orders">
      <ToastContainer />
      <div>
        <DataTable
          value={Orders}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column field="id" header="Id"></Column>
          <Column body={fullNameTemplate} header="Name"></Column>
          <Column field="phone" header="phone"></Column>
          <Column field="state" header="Wilaya"></Column>
          <Column field="commune" header="Commune"></Column>
          <Column field="fullAddress" header="Address"></Column>
          <Column body={statusBodyTemplate} header="Status"></Column>
          <Column body={dateBodyTemplate} header="date"></Column>
          <Column body={totalBodyTemplate} header="total"></Column>
          <Column body={actionTemplate} header="Actions"></Column>
        </DataTable>
      </div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => setModalShow(false)}
        show={modalShow}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Cart</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!!cart && cart.length ? (
            cart.map(i => (
              <div key={i.id} className="d-flex">
                <Image
                  src={i.img}
                  roundedCircle
                  width={60}
                  alt={"product image"}
                />
                <div className="px-2 d-flex align-items-center justify-content-between flex-grow-1">
                  <div className="d-flex flex-column justify-content-center">
                    <p className="m-0 p-0">
                      <b>{i.product}</b>
                    </p>
                    <p className="m-0 p-0">
                      {i.qty} X {i.price}
                    </p>
                  </div>
                  <div className="d-flex">
                    <div
                      style={{
                        height: "20px",
                        width: "20px",
                        backgroundColor: i.options.color
                      }}
                      className=" rounded-circle ms-2"
                    />
                    <div
                      className=" p-2 ms-2 d-flex justify-content-center align-items-center shadow-lg"
                      style={{
                        height: "20px",
                        width: "20px",
                        border: "1px solid black"
                      }}
                    >
                      {i.options.size}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center">no items</p>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </Basic>
  );
}

export default Orders;

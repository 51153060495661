import "./App.css";
import Login from "./pages/login/login";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "./firebase";

import { useSelector, useDispatch } from "react-redux";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import Home from "./pages/home/home";
import Categories from "./pages/categories/categories";
import Products from "./pages/products/products";
import Orders from "./pages/orders/orders";
import Settings from "./pages/settings/settings";
import { fetchCategories } from "./redux/categories/categories";
import { fetchProducts } from "./redux/products/products";
import { fetchOrders } from "./redux/orders/orders";
import { fetchStore } from "./redux/store/store";
import { useState } from "react";
import Shape from "./pages/shape/shape";
import Color from "./pages/color/color";
import Size from "./pages/size/size";
import Category from "./pages/category/category";

function App() {
  const [user, setUser] = useState(false)

  const dispatch = useDispatch();
  const auth = getAuth();

  onAuthStateChanged(auth, user => {
    if (user) {
      setUser(true)
      const unsub2 = onSnapshot(
        query(collection(db, "filter"), where("deleted", "==", false)),
        snapShot => {
          let list = [];
          snapShot.docs.forEach(doc => {
            list.push({ id: doc.id, ...doc.data() });
          });
          //console.log(list)
          dispatch(fetchCategories(list));
        }
      );
      const unsub = onSnapshot(
        query(collection(db, "products"), where("deleted", "==", false)),
        snapShot => {
          let list = [];
          snapShot.docs.forEach(doc => {
            list.push({ id: doc.id, ...doc.data() });
          });
          dispatch(fetchProducts(list));
        }
      );
      const unsub3 = onSnapshot(
        query(collection(db, "orders"), where("deleted", "==", false)),
        snapShot => {
          let list = [];
          snapShot.docs.forEach(doc => {
            list.push({ id: doc.id, ...doc.data() });
          });
          dispatch(fetchOrders(list));
        }
      );
    } else {
      //dispatch(logoutUser(""));
      setUser(false)
    }
  });
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="*"
          element={<Navigate to={!!user ? "/home" : "/login"} />}
        />
        {!!user ? (
          <>
            <Route path="/home" element={<Home />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/products" element={<Products />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/shape" element={<Shape />} />
            <Route path="/color" element={<Color />} />
            <Route path="/size" element={<Size />} />
            <Route path="/category" element={<Category />} />
          </>
        ) : (
          <>
            <Route path="/login" element={<Login />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;

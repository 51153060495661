import { useState } from "react";
import Basic from "../../layouts/basic/Basic";
import Nav from 'react-bootstrap/Nav';
import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { getAuth, updatePassword, signOut } from 'firebase/auth';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import ImageCompressor from 'react-image-file-resizer';
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { doc, setDoc } from "firebase/firestore";
import { db, storage } from "../../firebase";
import { TiDelete } from "react-icons/ti";

function Settings() {
  const store = useSelector(state => state.storeReduce);

  const [currentTap, setCurrentTap] = useState('profile');
  const notify = async message => {
    await sleep(500);
    toast.success(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored"
    });
  };
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const formik = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    onSubmit: async (values) => {
      const auth = getAuth();
      const user = auth.currentUser;
      try {
        // Update the user's password
        await updatePassword(user, values.password);
        console.log('Password updated successfully');
        notify("Password updated successfully")
        await sleep(3000).then(() => {
          signOut(auth)
        });


      } catch (error) {
        console.error('Error updating password:', error.message);
        signOut(auth)
      }
    }
  });
  const formik2 = useFormik({
    initialValues: {
      facebook: store?.facebook || "",
      instagram: store?.instagram || "",
      tiktok: store?.tiktok || ""
    },
    onSubmit: async (values) => {
      setDoc(doc(db, "store", store.id), { ...store, ...values }).then(
        notify("Update Done")
      );
    }
  });

  const formik3 = useFormik({
    initialValues: {
      sliders: store?.sliders || []
    },
    onSubmit: async (values) => {
      setDoc(doc(db, "store", store.id), { ...store, ...values }).then(
        notify("Update Done")
      );
    }
  });

  const formik4 = useFormik({
    initialValues: {
      logo: store?.logo || ""
    },
    onSubmit: async (values) => {
      setDoc(doc(db, "store", store.id), { ...store, ...values }).then(
        notify("Update Done")
      );
    }
  });

  const uploadImage = async (e, type) => {
    const gallery = []
    let count = 0;
    for (let i = 0; i < e.length; i++) {

      await ImageCompressor.imageFileResizer(
        e[i], // input file
        Infinity, // max width
        Infinity, // max height
        'WEBP', // output format
        80, // quality
        0, // rotation
        async (uri) => {
          const name = new Date().getTime() + i + "main";
          const storageRef = ref(storage, name);
          const uploadTask = uploadBytesResumable(storageRef, uri);
          uploadTask.on(
            "state_changed",
            snapshot => {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
              switch (snapshot.state) {
                case "paused":
                  console.log("Upload is paused");
                  break;
                case "running":
                  console.log("Upload is running");
                  break;
              }
            },
            error => {
              // Handle unsuccessful uploads
            },
            () => {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                if (type === "logo") {
                  formik4.setFieldValue('logo', downloadURL);
                }
                if (type === "gallery") {
                  gallery.push(downloadURL)
                  count++;
                  if (count === e.length) {
                    if (!!formik3.values.sliders) {
                      formik3.setValues({ ...formik3.values, sliders: [...formik3.values.sliders, ...gallery] })
                    } else {
                      formik3.setValues({ ...formik3.values, sliders: [...gallery] })
                    }

                  }
                }
              });
            }
          );

        },
        'file' // output type
      );

    }
  };

  console.log(formik4.values)
  return <Basic page="Settings">
    <ToastContainer />
    <div className="flex-grow-1 d-flex flex-column">
      <Nav variant="tabs" defaultActiveKey={currentTap}>
        <Nav.Item>
          <Nav.Link eventKey="profile" onClick={() => setCurrentTap("profile")}>Profile</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="meida" onClick={() => setCurrentTap("meida")}>Social Media</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="slider" onClick={() => setCurrentTap("slider")}>Slider</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="logo" onClick={() => setCurrentTap("logo")}>Logo</Nav.Link>
        </Nav.Item>
      </Nav>
      <div className="flex-grow-1 d-flex justify-content-center align-items-center">
        {currentTap === "profile" && < div className="h-100">
          <p className="fs-3 text-center">Update Password</p>
          <Form className="" onSubmit={formik.handleSubmit}>
            <div>
              <label htmlFor="password">Password:</label>
              <InputText
                type="password"
                placeholder="password"
                id="password"
                name="password"
                className="rounded-5 w-100"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                required
              />
            </div>
            <div>
              <label htmlFor="password_confirmation">password_confirmation:</label>
              <InputText
                type="password"
                placeholder="password_confirmation"
                id="password_confirmation"
                name="password_confirmation"
                className="rounded-5 w-100"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password_confirmation}
                required
              />
            </div>
            <div className="py-2 text-end">
              <Button
                label={"Update"}
                severity="success"
                type="submit"
                raised
                rounded
                disabled={
                  (formik.values.password_confirmation !== formik.values.password)
                  ||
                  (formik.values.password_confirmation.length < 8)
                }
              />
            </div>
          </Form>
        </div>}
        {currentTap === "meida" && < div className="h-100">
          <p className="fs-3 text-center">Update Social Media</p>
          <Form className="" onSubmit={formik2.handleSubmit}>
            <div>
              <label htmlFor="facebook">facebook:</label>
              <InputText
                placeholder="facebook"
                id="facebook"
                name="facebook"
                className="rounded-5 w-100"
                onChange={formik2.handleChange}
                onBlur={formik2.handleBlur}
                value={formik2.values.facebook}

              />
            </div>
            <div>
              <label htmlFor="instagram">instagram:</label>
              <InputText
                placeholder="instagram"
                id="instagram"
                name="instagram"
                className="rounded-5 w-100"
                onChange={formik2.handleChange}
                onBlur={formik2.handleBlur}
                value={formik2.values.instagram}

              />
            </div>
            <div>
              <label htmlFor="tiktok">tiktok:</label>
              <InputText
                placeholder="tiktok"
                id="tiktok"
                name="tiktok"
                className="rounded-5 w-100"
                onChange={formik2.handleChange}
                onBlur={formik2.handleBlur}
                value={formik2.values.tiktok}

              />
            </div>
            <div className="py-2 text-end">
              <Button
                label={"Update"}
                severity="success"
                type="submit"
                raised
                rounded
              />
            </div>
          </Form>
        </div>}
        {currentTap === "slider" && <div className="h-100 ">
          <p className="fs-3 text-center">Update Slider</p>
          <Form className="row" onSubmit={formik3.handleSubmit}>
            {
              formik3.values.sliders.map((img, index) =>
                <div className="d-flex flex-column col-sm-3 col-12"key={img}>
                  <TiDelete
                    size={30}
                    className="text-danger mx-auto"
                    onClick={() => {
                      const updatedSizes = [...formik3.values.sliders]; // Create a copy of the array
                      updatedSizes.splice(index, 1); // Remove the element at the specified index
                      formik3.setFieldValue('sliders', updatedSizes); // Update the 'sizes' field with the new array
                    }}
                  />
                  <img
                    
                    width={150}
                    height={120}
                    className="text-center mx-auto"
                    src={img}
                  />
                </div>

              )
            }
            <Form.Control type="file" className="my-2" multiple onChange={(e) => uploadImage(e.target.files, 'gallery')} />
            <div className="py-2 text-end">
              <Button
                label={"Update"}
                severity="success"
                type="submit"
                raised
                rounded
              />
            </div>
          </Form>
        </div>}
        {currentTap === "logo" && <div className="h-100 ">
          <p className="fs-3 text-center">Update Logo</p>
          <Form className="row" onSubmit={formik4.handleSubmit}>

            <div className="d-flex flex-column mx-auto col-sm-3 col-12">
              <TiDelete
                size={30}
                className="text-danger mx-auto"
                onClick={() => {
                  formik4.setFieldValue('logo', ""); // Update the 'sizes' field with the new array
                }}
              />
              <img
                width={150}
                height={150}
                className="text-center mx-auto"
                src={formik4.values.logo}
              />
            </div>
            <Form.Control type="file" className="my-2"  onChange={(e) => uploadImage(e.target.files, 'logo')} />
            <div className="py-2 text-end">
              <Button
                label={"Update"}
                severity="success"
                type="submit"
                raised
                rounded
              />
            </div>
          </Form>
        </div>}
      </div>
    </div>
  </Basic >;
}

export default Settings;

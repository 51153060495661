import Basic from "../../layouts/basic/Basic";
import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import ImageCompressor from 'react-image-file-resizer';
import { Column } from "primereact/column";
import { useSelector } from "react-redux";
import { FcPlus, FcSearch, FcSettings, FcEmptyTrash, FcCheckmark } from "react-icons/fc";
import { TiDelete } from "react-icons/ti";
import Image from 'react-bootstrap/Image';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "primereact/button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import {
  addDoc,
  collection,
  serverTimestamp,
  setDoc,
  doc
} from "firebase/firestore";

function Products() {
  const user = useSelector(state => state.userReducer.user);
  const products = useSelector(state => state.productsReduce);
  const categories = useSelector(state => state.categoriesReduce).filter((category) => category.type === "category");;
  const brands = useSelector(state => state.categoriesReduce).filter((category) => category.type === "brand");
  const sizes = useSelector(state => state.categoriesReduce).filter((category) => category.type === "size");
  const colors = useSelector(state => state.categoriesReduce).filter((category) => category.type === "color");
  const shapes = useSelector(state => state.categoriesReduce).filter((category) => category.type === "shape");
  const genders = useSelector(state => state.categoriesReduce).filter((category) => category.type === "gender");

  const [modalShow, setModalShow] = useState(false);
  const [addSize, setAddSize] = useState(false);
  const [newSize, setNewSize] = useState("");

  const [addColor, setAddColor] = useState(false);
  const [newColor, setNewColor] = useState("");

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const notify = async message => {
    await sleep(500);
    toast.success(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored"
    });
  };

  const formik = useFormik({
    initialValues: {
      brand_id: "",
      brand_name: "",
      category_id: "",
      category_name: "",
      color_id: "",
      color_name: "",
      gender_id: "",
      gender_name: "",
      name: "",
      shape_id: "",
      shape_name: "",
      size_id: "",
      size_name: "",
      slider: [],
      price: null,
      deleted: false,
    },
    onSubmit: values => {
      if (!values.id) {
        addDoc(collection(db, "products"), {
          ...values,
          user,
          createdAt: serverTimestamp()
        }).then(setModalShow(false), notify("Add Done"));
      } else {
        setDoc(doc(db, "products", values.id), values).then(
          setModalShow(false),
          notify("Update Done")
        );
      }
      formik.resetForm();
    }
  });

  const imageBodyTemplate = rowData => {
    return (
      <img
        src={`${rowData.image}`}
        height="75px"
        onError={e =>
          (e.target.src = "https://foorweb.net/product-foorweb.png")
        }
        alt={rowData.image}
        className="product-image"
      />
    );
  };
  const actionTemplate = rowData => {
    return (
      <div className="d-flex text-start">
        <div
          style={{
            height: "40px",
            width: "40px"
          }}
          className="bg-info rounded-circle me-2 d-flex justify-content-center align-items-center"
        >
          <FcSettings
            size={30}
            onClick={() => {
              setModalShow(true);
              formik.setValues(rowData);
            }}
          />
        </div>
        <div
          style={{
            height: "40px",
            width: "40px"
          }}
          className="bg-danger rounded-circle d-flex justify-content-center align-items-center"
        >
          <FcEmptyTrash size={30} onClick={() => handleDelete(rowData)} />
        </div>
      </div>
    );
  };

  const handleDelete = async data => {
    setDoc(doc(db, "products", data.id), { ...data, deleted: true }).then(
      notify("Delete Done")
    );
  };

  const dateBodyTemplate = rowData => {
    if (!!rowData.createdAt) {
      const date = new Date(rowData.createdAt.seconds * 1000);
      return <span>{date.toLocaleString().toString()}</span>;
    }
  };

  const SizeBodyTemplate = rowData => {
    return (sizes.filter((size) => size.id === rowData.size_id)[0] || {}).name || "";
  };

  const genderBodyTemplate = rowData => {
    return (genders.filter((gender) => gender.id === rowData.gender_id)[0] || {}).name || "";
  };

  const ShapeBodyTemplate = rowData => {
    return (shapes.filter((shape) => shape.id === rowData.shape_id)[0] || {}).name || "";
  };

  const colorBodyTemplate = rowData => {
    return (colors.filter((color) => color.id === rowData.color_id)[0] || {}).name || "";
  };
  const categoryBodyTemplate = rowData => {
    return (categories.filter((category) => category.id === rowData.category_id)[0] || {}).name || "";
  };
  const barndBodyTemplate = rowData => {
    return (brands.filter((brand) => brand.id === rowData.brand_id)[0] || {}).name || "";
  };

  const uploadImage = async (e, type) => {
    const gallery = []
    let count = 0;
    for (let i = 0; i < e.length; i++) {

      await ImageCompressor.imageFileResizer(
        e[i], // input file
        Infinity, // max width
        Infinity, // max height
        'WEBP', // output format
        80, // quality
        0, // rotation
        async (uri) => {
          const name = new Date().getTime() + i + "main";
          const storageRef = ref(storage, name);
          const uploadTask = uploadBytesResumable(storageRef, uri);
          uploadTask.on(
            "state_changed",
            snapshot => {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
              switch (snapshot.state) {
                case "paused":
                  console.log("Upload is paused");
                  break;
                case "running":
                  console.log("Upload is running");
                  break;
              }
            },
            error => {
              // Handle unsuccessful uploads
            },
            () => {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                if (type === "main") {
                  formik.setValues({ ...formik.values, image: downloadURL });
                }
                if (type === "gallery") {
                  gallery.push(downloadURL)
                  count++;
                  if (count === e.length) {
                    if (!!formik.values.gallery) {
                      formik.setValues({ ...formik.values, slider: [...formik.values.gallery, ...gallery] })
                    } else {
                      formik.setValues({ ...formik.values, slider: [...gallery] })
                    }

                  }
                }
              });
            }
          );

        },
        'file' // output type
      );

    }
  };
  return (
    <Basic page="Products">
      <ToastContainer />
      <div>
        <div className="d-flex justify-content-between p-2 px-4">
          <div className="p-input-icon-left">
            <FcSearch />
            <InputText placeholder="Search" className="rounded-5" />
          </div>
          <div>
            <Button
              label="Add Product"
              severity="success"
              onClick={() => { formik.resetForm(); setModalShow(true) }}
              raised
              rounded
              icon={<FcPlus size={20} />}
              iconPos="right"
            />
          </div>
        </div>
        <DataTable
          value={products}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column body={imageBodyTemplate} header="Image"></Column>
          <Column field="name" header="Name"></Column>
          <Column field="price" header="price"></Column>
          <Column body={barndBodyTemplate} header="brand"></Column>
          <Column body={categoryBodyTemplate} header="category"></Column>
          <Column body={colorBodyTemplate} header="color"></Column>
          <Column body={genderBodyTemplate} header="gender"></Column>
          <Column body={ShapeBodyTemplate} header="shape"></Column>
          <Column body={SizeBodyTemplate} header="size"></Column>
          <Column body={dateBodyTemplate} header="date"></Column>
          <Column body={actionTemplate} header="Actions"></Column>
        </DataTable>
      </div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => { setModalShow(false); formik.resetForm() }}
        show={modalShow}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Product
          </Modal.Title>
        </Modal.Header>
        <Form className="" onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="row">
              <div className="col-sm-4 col-12">
                {formik.values.image ? (
                  <div className="d-flex justify-content-center">
                    <img
                      width="200px"
                      height="200px"
                      className="text-center mt-2"
                      src={formik.values.image}
                    />
                  </div>

                ) : (
                  <div className="d-flex justify-content-center">
                    <img
                      width="200px"
                      height="200px"
                      className="text-center mt-2"
                      src={
                        "https://t3.ftcdn.net/jpg/05/52/37/18/360_F_552371867_LkVmqMEChRhMMHDQ2drOS8cwhAWehgVc.jpg"
                      }
                    />
                  </div>

                )}
                <Form.Control
                  type="file"
                  className="mt-2"
                  onChange={e => uploadImage([e.target.files[0]], "main")}
                  required={!formik.values.image}
                />
              </div>
              <div className="col-sm-8 col-12 row ">
                <div className="col-sm-6 col-12">
                  <label htmlFor="Title">Title:</label>
                  <InputText
                    placeholder="Title"
                    id="name"
                    name="name"
                    className="rounded-5 w-100"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    required
                  />
                </div>
                <div className="col-sm-6 col-12">
                  <label htmlFor="brand">Brand:</label>
                  <Form.Select
                    aria-label="brand_id"
                    id="brand_id"
                    name="brand_id"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.brand_id}
                    className="rounded-5 w-100 p-2"
                    required
                  >
                    <option>brand</option>
                    {!!brands &&
                      brands.map(category => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                  </Form.Select>
                </div>
                <div className="col-sm-6 col-12">
                  <label htmlFor="size">Size:</label>
                  <Form.Select
                    aria-label="size_id"
                    id="size_id"
                    name="size_id"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.size_id}
                    className="rounded-5 w-100 p-2"
                    required
                  >
                    <option>size</option>
                    {!!sizes &&
                      sizes.map(category => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                  </Form.Select>
                </div>
                <div className="col-sm-6 col-12">
                  <label htmlFor="size">color:</label>
                  <Form.Select
                    aria-label="color_Id"
                    id="color_id"
                    name="color_id"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.color_id}
                    className="rounded-5 w-100 p-2"
                    required
                  >
                    <option>color</option>
                    {!!colors &&
                      colors.map(category => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                  </Form.Select>
                </div>
                <div className="col-sm-6 col-12">
                  <label htmlFor="shape">shape:</label>
                  <Form.Select
                    aria-label="shape_id"
                    id="shape_id"
                    name="shape_id"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.shape_id}
                    className="rounded-5 w-100 p-2"
                    required
                  >
                    <option>shape</option>
                    {!!shapes &&
                      shapes.map(category => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                  </Form.Select>
                </div>
                <div className="col-sm-6 col-12">
                  <label htmlFor="shape">Gender:</label>
                  <Form.Select
                    aria-label="gender_id"
                    id="gender_id"
                    name="gender_id"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.gender_id}
                    className="rounded-5 w-100 p-2"
                    required
                  >
                    <option>Gender</option>
                    {!!genders &&
                      genders.map(category => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                  </Form.Select>
                </div>
                <div className="col-sm-6 col-12">
                  <label htmlFor="shape">category:</label>
                  <Form.Select
                    aria-label="category_id"
                    id="category_id"
                    name="category_id"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.category_id}
                    className="rounded-5 w-100 p-2"
                    required
                  >
                    <option>Gender</option>
                    {!!categories &&
                      categories.map(category => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                  </Form.Select>
                </div>
                <div className="col-sm-6 col-12">
                  <label htmlFor="price">price:</label>
                  <InputText
                    placeholder="price"
                    id="price"
                    name="price"
                    type="number"
                    min={0}
                    className="rounded-5 w-100"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.price}
                    required
                  />
                </div>

              </div>
            </div>
            <hr />
            <div className="row">
              <Form.Group controlId="formFileMultiple" className="mb-3 col-12 col-sm-12  text-center">
                <Form.Label>gallery</Form.Label>
                <div className="row">
                  {
                    formik.values.slider && formik.values.slider.map((img, index) => (
                      <div key={index} className="col-4 col-sm-2">
                        <TiDelete
                          size={30}
                          className="text-danger mx-auto"
                          onClick={() => {
                            const updatedSizes = [...formik.values.slider]; // Create a copy of the array
                            updatedSizes.splice(index, 1); // Remove the element at the specified index
                            formik.setFieldValue('slider', updatedSizes); // Update the 'sizes' field with the new array
                          }}
                        />
                        <Image width="120px" style={{ objectFit: 'cover', height: '5rem' }} src={img} />
                      </div>
                    ))
                  }
                </div>
                <Form.Control type="file" className="mt-2" multiple onChange={(e) => uploadImage(e.target.files, 'gallery')} />
              </Form.Group>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button
              label={!formik.values.id ? "Add" : "Update"}
              severity="success"
              type="submit"
              raised
              rounded
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </Basic>
  );
}

export default Products;

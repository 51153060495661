/* eslint-disable */
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useFormik } from "formik";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/user/user";

function Login() {
  const auth = getAuth();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    onSubmit: values => {
      // Handle form submission (e.g., send a request to your API)
      signInWithEmailAndPassword(auth, values.email, values.password)
        .then(userCredential => {
          const user = userCredential.user;
          dispatch(loginUser(user.uid));
          localStorage.setItem("token", user.uid);
        })
        .catch(error => {
          console.log(error);
        });
    }
  });

  return (
    <div
      className="App d-flex justify-content-center align-items-center"
      style={{ backgroundColor: "#051E4A" }}
    >
      <div className="border border-2 p-4 bg-white col-sm-3 col-10">
        <Form className="" onSubmit={formik.handleSubmit}>
          <p className="fs-2 fw-bolder text-center fst-italic">Login</p>
          <Form.Group className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              id="firstName"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              id="password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
          </Form.Group>
          <div className="text-end">
            <Button variant="success" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Login;

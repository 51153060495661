import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useDispatch } from "react-redux";
import { signOut, getAuth } from "firebase/auth";
import { logoutUser } from "../../redux/user/user";
import { Link, useLocation } from "react-router-dom";
import { FcBusinesswoman, FcCursor } from "react-icons/fc";

function NavBar1() {
  const dispatch = useDispatch();
  const location = useLocation();
  const auth = getAuth();

  const handleSLogout = () => {
    signOut(auth)
      .then(() => {
        dispatch(logoutUser(""));
        localStorage.setItem("token", "");
      })
      .catch(error => {
        // An error happened.
        console.error("Error signing out:", error);
      });
  };
  return (
    <>
      {[false].map(expand => (
        <Navbar
          key={expand}
          expand={expand}
          className="back-color1 fixed-top"
          style={{ height: "60px" }}
        >
          <Container fluid>

            <Navbar.Brand href="#" className="bg-white rounded rounded-2 px-2">
              <img
                src={"https://firebasestorage.googleapis.com/v0/b/mati-f0ce0.appspot.com/o/434377984_753034510300678_5638654978075444540_n-removebg-preview.png?alt=media&token=cb5d0599-12e2-42bf-a8fe-17cce2958969"} // Adjust the path based on your image folder structure
                alt={"logo"}
                className=" rounded rounded-3"
                width="30"
                height={"30"}
              />
            </Navbar.Brand>
            <Navbar.Toggle className="bg-white" aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Menu
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="d-flex flex-column">
                
                <div className=" text-white">
     
                  <hr />
                  <div className="d-flex my-2 justify-content-center">
                  <a
                    href=""
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <div className="d-flex align-items-center border border-5 rounded rounded-3 back-color1 mx-auto">
                      <p className="fs-6 m-1">Visit your Website</p>
                      <FcCursor size={40} />
                    </div>
                  </a>
                </div>
                <hr />
                </div>
                <div className="flex-grow-1 fw-bolder fst-italic">
                  <div
                    style={{
                      height: "40px",
                      width: "200px"
                    }}
                    className={`mx-auto my-2  d-flex align-items-center border border-2 justify-content-center rounded-3 ${location.pathname === "/home" ? "back-color1 text-white" : ""
                      }`}
                  >
                    <Link
                      className="fs-4 p-0 m-0"
                      style={{ color: "inherit", textDecoration: "none" }}
                      to={`/home`}
                    >
                      Home
                    </Link>
                  </div>
                  <div
                    style={{
                      height: "40px",
                      width: "200px"
                    }}
                    className={`mx-auto my-2  d-flex align-items-center border border-2 justify-content-center rounded-3 ${location.pathname === "/orders" ? "back-color1 text-white" : ""
                      }`}
                  >
                    <Link
                      className="fs-4 p-0 m-0"
                      style={{ color: "inherit", textDecoration: "none" }}
                      to={`/orders`}
                    >
                      Orders
                    </Link>
                  </div>
                  <div
                    style={{
                      height: "40px",
                      width: "200px"
                    }}
                    className={`mx-auto my-2  d-flex align-items-center border border-2 justify-content-center rounded-3 ${location.pathname === "/products" ? "back-color1 text-white" : ""
                      }`}
                  >
                    <Link
                      className="fs-4 p-0 m-0"
                      style={{ color: "inherit", textDecoration: "none" }}
                      to={`/products`}
                    >
                      Products
                    </Link>
                  </div>
                  <div
                    style={{
                      height: "40px",
                      width: "200px"
                    }}
                    className={`mx-auto my-2  d-flex align-items-center border border-2 justify-content-center rounded-3 ${location.pathname === "/shape" ? "back-color1 text-white" : ""
                      }`}
                  >
                    <Link
                      className="fs-4 p-0 m-0"
                      style={{ color: "inherit", textDecoration: "none" }}
                      to={`/shape`}
                    >
                      Shape
                    </Link>
                  </div>
                  <div
                    style={{
                      height: "40px",
                      width: "200px"
                    }}
                    className={`mx-auto my-2  d-flex align-items-center border border-2 justify-content-center rounded-3 ${location.pathname === "/color" ? "back-color1 text-white" : ""
                      }`}
                  >
                    <Link
                      className="fs-4 p-0 m-0"
                      style={{ color: "inherit", textDecoration: "none" }}
                      to={`/color`}
                    >
                      Color
                    </Link>
                  </div>
                  <div
                    style={{
                      height: "40px",
                      width: "200px"
                    }}
                    className={`mx-auto my-2  d-flex align-items-center border border-2 justify-content-center rounded-3 ${location.pathname === "/size" ? "back-color1 text-white" : ""
                      }`}
                  >
                    <Link
                      className="fs-4 p-0 m-0"
                      style={{ color: "inherit", textDecoration: "none" }}
                      to={`/size`}
                    >
                      Size
                    </Link>
                  </div>
                  <div
                    style={{
                      height: "40px",
                      width: "200px"
                    }}
                    className={`mx-auto my-2  d-flex align-items-center border border-2 justify-content-center rounded-3 ${location.pathname === "/category" ? "back-color1 text-white" : ""
                      }`}
                  >
                    <Link
                      className="fs-4 p-0 m-0"
                      style={{ color: "inherit", textDecoration: "none" }}
                      to={`/category`}
                    >
                      Category
                    </Link>
                  </div>
                  
                  <div
                    style={{
                      height: "40px",
                      width: "200px"
                    }}
                    className={`mx-auto my-2  d-flex align-items-center border border-2 justify-content-center rounded-3 ${location.pathname === "/categories" ? "back-color1 text-white" : ""
                      }`}
                  >
                    <Link
                      className="fs-4 p-0 m-0"
                      style={{ color: "inherit", textDecoration: "none" }}
                      to={`/categories`}
                    >
                      Categories
                    </Link>
                  </div>
                  {/*<div
                    style={{
                      height: "40px",
                      width: "200px"
                    }}
                    className={`mx-auto my-2  d-flex align-items-center border border-2 justify-content-center rounded-3 ${location.pathname === "/settings" ? "back-color1" : ""
                      }`}
                  >
                    <Link
                      className="fs-4 p-0 m-0"
                      style={{ color: "inherit", textDecoration: "none" }}
                      to={`/settings`}
                    >
                      Settings
                    </Link>
                    </div>*/}
                </div>
                <div>
                  <hr />
                  <div
                    style={{
                      height: "40px",
                      width: "200px"
                    }}
                    className="bg-danger mx-auto d-flex align-items-center justify-content-center rounded-3"
                    onClick={handleSLogout}
                  >
                    <p className="fs-4 text-white p-0 m-0 fw-bolder fst-italic">
                      LogOut
                    </p>
                  </div>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default NavBar1;
